import React, { FC } from 'react';
import { Button, Grid } from '@mui/material';
import { Box } from '@mui/system';
import logo from '../../assets/images/logo_acme.png';
import { useNavigate } from 'react-router-dom';

export const Home: FC = () => {
  const navigate = useNavigate();

  const next = () => {
    navigate('/profile');
  };

  return (
    <Grid container spacing={2} mt={24}>
      <Grid item xs={5}>
        <Box p="5" mt="10">
          <img src={logo} />
        </Box>
      </Grid>
      <Grid item xs={7}>
        Welcome to ACME CO. where you can gain insight into your health in just 30 seconds. Turn on
        your camera and take a 30 second video of your face.
      </Grid>
      <Grid item xs={12}>
        <Box mt={24} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button variant="contained" onClick={next} sx={{ mt: 3, ml: 1 }}>
            Go to measurement
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
