import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { Controller, useForm } from 'react-hook-form';
import { wmsUrl } from '../../common/config';
import { useEnvironments } from '../../contexts/environmentsContext';
import { useError } from '../../contexts/errorContext';


export const Profile: FC = () => {
  const { environments } = useEnvironments();
  const { showError } = useError();
  const { register, handleSubmit, control } = useForm();

  const fetchEncryptedProfile = async (payload: any) => {
    try {
      const response = await fetch('/api/encryptProfile', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          profileData: JSON.stringify(payload),
          shouldUseTwoWayEncryption: environments.TWO_WAY_ENCRYPTION === 'true'
        })
      });
  
      if (!response.ok) {
        throw new Error(`Network response was ${response.status} for encryptProfile`);
      }
  
      const encryptedProfileResponse = await response.json();
      const encryptedProfile = encryptedProfileResponse.data;
      console.log(encryptedProfile);
      return encryptedProfile;
    } catch (error) {
      showError(`Error fetching encrypted profile: ${(error as Error).message}`);
      throw error;
    }
  };

  const fetchConfigId = async () => {
    try {
      const response = await fetch('/api/configId');
      if (!response.ok) {
        throw new Error(`Network response was ${response.status} for configId`);
      }
      return await response.json();
    } catch (error) {
      showError(`Error fetching configId: ${(error as Error).message}`);
      throw error;
    }
  };

  const fetchToken = async () => {
    try {
      const response = await fetch('/api/token');
      if (!response.ok) {
        throw new Error(`Network response was ${response.status} for token`);
      }
      return await response.json();
    } catch (error) {
      showError(`Error fetching token: ${(error as Error).message}`);
      throw error;
    }
  };

  const onSubmit = async (data: any) => {
    try {
      const { age, diabetic, height, medication, gender, smoker, weight, identifier, sessionId
      } = data;

      const configResponseData = await fetchConfigId();

      // Get a token from the back end
      const tokenResponseData = await fetchToken();
      const { Token, RefreshToken } = tokenResponseData;

      const payload = {
        identifier, // Unique identifier for the user, it will be used to tag the measurement
        age,
        height,
        weight,
        gender,
        smoking: smoker === 'yes' ? '1' : '0',
        bloodpressuremedication: medication === 'yes' ? '1' : '0',
        diabetes: diabetic,
      };

      // e2e profile encryption
      const encryptedProfile = await fetchEncryptedProfile(payload);

      const sessionIdValue = !!sessionId ? sessionId : 'undefined';

      window.location.href = `https://${wmsUrl}/c/${configResponseData.configId}/${encodeURIComponent(
        encryptedProfile
      )}/${encodeURIComponent(Token)}/${encodeURIComponent(RefreshToken)}/${sessionIdValue}`;
    } catch (error) {
      showError(`Submit failed due to: ${(error as Error).message}`);
    }
  };

  return (
    <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography component="h1" variant="h4" align="center">
          Profile
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <TextField
              id="identifier"
              label="Identifier"
              fullWidth
              variant="standard"
              {...register('identifier')}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              id="sessionId"
              label="Session ID"
              fullWidth
              variant="standard"
              {...register('sessionId')}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              id="age"
              label="What is your current age?"
              fullWidth
              variant="standard"
              {...register('age')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="height"
              label="Height (cm)"
              fullWidth
              variant="standard"
              {...register('height')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="weight"
              label="Weight (kg)"
              fullWidth
              variant="standard"
              {...register('weight')}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Controller
              name="gender"
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <FormControl component="fieldset">
                  <FormLabel component="legend">What is your sex at birth?</FormLabel>
                  <RadioGroup row aria-label="gender" name={name} value={value} onChange={onChange}>
                    <FormControlLabel value="male" control={<Radio />} label="Male" />
                    <FormControlLabel value="female" control={<Radio />} label="Female" />
                  </RadioGroup>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Controller
              name="smoker"
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <FormControl component="fieldset">
                  <FormLabel component="legend">Do you smoke?</FormLabel>
                  <RadioGroup row aria-label="smoke" name={name} value={value} onChange={onChange}>
                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Controller
              name="medication"
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    Are you taking blood pressure medication?
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-label="medication"
                    name={name}
                    value={value}
                    onChange={onChange}
                  >
                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Controller
              name="diabetic"
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <FormControl component="fieldset">
                  <FormLabel component="legend">Are you diabetic?</FormLabel>
                  <RadioGroup
                    row
                    aria-label="diabetic"
                    name={name}
                    value={value}
                    onChange={onChange}
                  >
                    <FormControlLabel value="type1" control={<Radio />} label="Type 1" />
                    <FormControlLabel value="type2" control={<Radio />} label="Type 2" />
                    <FormControlLabel value="0" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" type="submit" sx={{ mt: 3, ml: 1 }}>
            Continue
          </Button>
        </Box>
      </form>
    </Paper>
  );
};
