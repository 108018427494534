import React, { FC } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Home, Profile, Results } from '../Pages';
import { ErrorProvider } from '../contexts/errorContext';
import ErrorBoundary from './ErrorBoundary';

export const App: FC = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#c50059',
      },
      secondary: {
        main: '#00c56c',
      },
    },
  });

  return (
    <ErrorProvider>
      <ErrorBoundary>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AppBar
            position="absolute"
            color="primary"
            elevation={0}
            sx={{
              position: 'relative',
              borderBottom: t => `1px solid ${t.palette.divider}`,
            }}
          >
            <Toolbar>
              <Typography variant="h6" color="inherit" noWrap>
                ACME CO
              </Typography>
            </Toolbar>
          </AppBar>
          <Container component="main" maxWidth="md" sx={{ mb: 4, mt: 4 }}>
            <Router>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/results" element={<Results />} />
                <Route path="/profile" element={<Profile />} />
              </Routes>
            </Router>
          </Container>
        </ThemeProvider>
      </ErrorBoundary>
    </ErrorProvider>
  );
};
