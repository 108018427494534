import React, { FC, Fragment, useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useQuery } from '../../hooks';
import { useError } from '../../contexts/errorContext';
import { useEnvironments } from '../../contexts/environmentsContext';

export const Results: FC = () => {
  const [resultsData, setResultsData] = useState<any>();
  const [callBackErrors, setCallbackErrors] = useState<(string | undefined)[]>([]);
  const query = useQuery();
  const { showError } = useError();
  const { environments, loading } = useEnvironments();

  useEffect(() => {
    const loadResults = async () => {
      if (loading) return; // Wait for the configuration to be loaded
      const results = query.get('results');
      const dataId = query.get('dataId'); // only defined when results are sent in post request
      const errors = query.get('error');

      try {
        // Check for errors
        if (!!errors) {
          const validationErrors = errors
            .split(',')
            .map((e: string) => {
              if (!!e) return e;
            })
            .filter(n => n);

          setCallbackErrors([...validationErrors]);
        }

        if (dataId) {
          const response = await fetch('/api/retrieveResults', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ measurementId: dataId }),
          });
          const data = await response.json();

          setResultsData(data.results);
          return;
        }

        if (!results || results === '') {
          return;
        }

        let resultsObj = {};

        // If two way encryption is enabled, the decryption endpoint will be called to retrieve the decrypted measurement results
        if (environments.TWO_WAY_ENCRYPTION === 'true') {
          const response = await fetch('/api/decryptResults', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              resultPayload: results,
            }),
          });

          if (!response.ok) {
            throw new Error(`Network response: ${response.status} for decryptResults`);
          }

          const decryptedResults = await response.json();
          resultsObj = decryptedResults.data;
        } else {
          // no decryption is required
          resultsObj = JSON.parse(window.atob(results));
        }
        setResultsData(resultsObj);
      } catch (e) {
        showError(`Displaying Results failed due: ${(e as Error).message}`);
      }
    };
    loadResults();
  }, [loading]);

  const renderValue = (valueName: string, decimalDigits: number) => {
    return resultsData && resultsData[valueName] && !isNaN(resultsData[valueName])
      ? Math.round(resultsData[valueName] * Math.pow(10, decimalDigits)) /
          Math.pow(10, decimalDigits)
      : '-';
  };

  const renderErrors = () => {
    if (callBackErrors.length > 0) {
      return (
        <Fragment>
          <Typography component="h1" variant="h4" align="center" color="red">
            Errors
          </Typography>
          {callBackErrors.map(errorItem => (
            <Typography color="red" variant="body1">
              {errorItem}
            </Typography>
          ))}
        </Fragment>
      );
    }
  };

  return loading ? (
    <Typography component="h1" variant="h2" align="center">
      Loading Results...
    </Typography>
  ) : (
    <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
      {renderErrors()}
      <Typography component="h1" variant="h4" align="center">
        Results
      </Typography>
      <Grid container spacing={3} mt={2}>
        <Grid item xs={12} sm={9}>
          <Typography variant="button">Heart Rate</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant="body1">{renderValue('HR_BPM', 0)}</Typography>
        </Grid>

        <Grid item xs={12} sm={9}>
          <Typography variant="button">Irregular Heartbeats</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant="body1">{renderValue('IHB_COUNT', 0)}</Typography>
        </Grid>

        <Grid item xs={12} sm={9}>
          <Typography variant="button">Breathing</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant="body1">{renderValue('BR_BPM', 0)}</Typography>
        </Grid>

        <Grid item xs={12} sm={9}>
          <Typography variant="button">Systolic Blood Pressure</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant="body1">{renderValue('BP_SYSTOLIC', 0)}</Typography>
        </Grid>

        <Grid item xs={12} sm={9}>
          <Typography variant="button">Diastolic Blood Pressure</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant="body1">{renderValue('BP_DIASTOLIC', 0)}</Typography>
        </Grid>

        <Grid item xs={12} sm={9}>
          <Typography variant="button">Heart Rate Variability</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant="body1">{renderValue('HRV_SDNN', 0)}</Typography>
        </Grid>

        <Grid item xs={12} sm={9}>
          <Typography variant="button">Cardiac Workload</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant="body1">{renderValue('BP_RPP', 0)}</Typography>
        </Grid>

        <Grid item xs={12} sm={9}>
          <Typography variant="button">Stress Index</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant="body1">{renderValue('MSI', 0)}</Typography>
        </Grid>

        <Grid item xs={12} sm={9}>
          <Typography variant="button">Body Mass Index</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant="body1">{renderValue('BMI_CALC', 0)}</Typography>
        </Grid>

        <Grid item xs={12} sm={9}>
          <Typography variant="button">Facial Skin Age</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant="body1">{renderValue('AGE', 0)}</Typography>
        </Grid>

        <Grid item xs={12} sm={9}>
          <Typography variant="button">Waist-to-Height Ratio</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant="body1">{renderValue('WAIST_TO_HEIGHT', 1)}</Typography>
        </Grid>

        <Grid item xs={12} sm={9}>
          <Typography variant="button">Body Shape Index</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant="body1">{renderValue('ABSI', 1)}</Typography>
        </Grid>

        <Grid item xs={12} sm={9}>
          <Typography variant="button">Session ID</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant="body1">{resultsData && resultsData.SESSION_ID}</Typography>
        </Grid>

        <Grid item xs={12} sm={9}>
          <Typography variant="button">identifier</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant="body1">{resultsData && resultsData.identifier}</Typography>
        </Grid>

        <Grid item xs={12} sm={9}>
          <Typography variant="button">MEASUREMENT_ID</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant="body1">{resultsData && resultsData.MEASUREMENT_ID}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};
